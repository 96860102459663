<template>
<div>
    <b-row>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-edit fa-md"></i> <span class="h5"> Gestión de proveedor </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="12">
                            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                <b-form @submit.stop.prevent="handleSubmit(guardarCambios)">
                                    <b-tabs content-class="mt-3" active-nav-item-class="font-weight-bold text-uppercase text-dark">
                                        <b-tab title="Datos generales" active>
                                            <b-row>
                                                <b-col lg="3">
                                                    <validation-provider name="fecha de registro" :rules="{ required: true }" v-slot="validationContext">
                                                        <b-form-group label="Fecha de registro:" class="mb-2">
                                                            <b-form-input type="date" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha de registro" v-model="datosProveedor.fechaRegistro"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="3">
                                                    <validation-provider name="codigo proveedor" :rules="{ required: true }" v-slot="validationContext">
                                                        <b-form-group label="Código Proveedor:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el proveedor" v-model="datosProveedor.codigo"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="6">
                                                    <validation-provider name="nombre o razón social" :rules="{ required: true }" v-slot="validationContext">
                                                        <b-form-group label="Nombre o Razón Social:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nombre o razón social" v-model="datosProveedor.razonSocial"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="4">
                                                    <validation-provider name="ruc" :rules="{required: true,numeric:true}" v-slot="validationContext">
                                                        <b-form-group label="RUC:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el ruc" v-model="datosProveedor.ruc"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="4">
                                                    <validation-provider name="localidad" v-slot="validationContext">
                                                        <b-form-group label="Localidad:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la localidad" v-model="datosProveedor.localidad"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="4">
                                                    <validation-provider name="domicilio" v-slot="validationContext">
                                                        <b-form-group label="Domicilio:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el domicilio" v-model="datosProveedor.domicilio"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="4">
                                                    <validation-provider name="teléfono" :rules="{required: true,numeric: true}" v-slot="validationContext">
                                                        <b-form-group label="Teléfono:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono" v-model="datosProveedor.telefono"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="4">
                                                    <validation-provider name="email" :rules="{required: true,email:true}" v-slot="validationContext">
                                                        <b-form-group label="Email:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el email" v-model="datosProveedor.email"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="4">
                                                    <validation-provider name="contacto" :rules="{required: true}" v-slot="validationContext">
                                                        <b-form-group label="Contacto:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el contacto" v-model="datosProveedor.contacto"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="4">
                                                    <validation-provider name="página web" v-slot="validationContext">
                                                        <b-form-group label="Página web:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la página web" v-model="datosProveedor.paginaWeb"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="4">
                                                    <validation-provider name="tipo" rules="required" v-slot="{errors}">
                                                        <b-form-group label="Tipo:" class="mb-2">
                                                            <v-select :reduce="comboTipoProveedor =>comboTipoProveedor.idTipoProveedor" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosProveedor.tipo , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosProveedor.tipo" :options="comboTipoProveedor" @search:blur="blurTipoProveedor">
                                                                <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                            </v-select>
                                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="4" class="mt-2 text-center">
                                                    <b-form-group label="Posibilidad de credito: ">
                                                        <b-form-radio-group small plain v-model="datosProveedor.probabilidadCredito" :options="radioPosibilidadCredito"></b-form-radio-group>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col lg="4">
                                                    <validation-provider name="banco" v-slot="validationContext">
                                                        <b-form-group label="Banco:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el banco" v-model="datosProveedor.banco"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="4">
                                                    <validation-provider name="n° de cuenta" v-slot="validationContext">
                                                        <b-form-group label="N° de cuenta:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el n° de cuenta" v-model="datosProveedor.nCuenta"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="4">
                                                    <validation-provider name="n° de cuenta de detracción" v-slot="validationContext">
                                                        <b-form-group label="N° de cuenta de detracción:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el n° de cuenta" v-model="datosProveedor.nCuentaDetraccion"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="4">
                                                    <validation-provider name="tipología de producto/servicio" v-slot="validationContext">
                                                        <b-form-group label="Tipología de producto / servicio:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la tipología" v-model="datosProveedor.tipologiaProducto"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="4">
                                                    <validation-provider name="criterio" rules="required" v-slot="{errors}">
                                                        <b-form-group label="Criterio de Evaluación:" class="mb-2">
                                                            <v-select :reduce="comboCriterio =>comboCriterio.idCriterio" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosProveedor.criterioEvaluacion , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosProveedor.criterioEvaluacion" :options="comboCriterio" @search:blur="blurCriterio">
                                                                <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                            </v-select>
                                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="4">
                                                    <validation-provider name="fecha de evaluación" :rules="{ required: true }" v-slot="validationContext">
                                                        <b-form-group label="Fecha de evaluación:" class="mb-2">
                                                            <b-form-input type="date" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha de evaluación" v-model="datosProveedor.fechaEvaluacion"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="4" class="mt-2 text-center">
                                                    <b-form-group label="¿Aprobado?">
                                                        <b-form-radio-group small plain v-model="datosProveedor.aprobado" :options="radioAprobado"></b-form-radio-group>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col lg="4" v-if="$route.params.id">
                                                    <validation-provider name="documento de calificación" v-slot="validationContext">
                                                        <b-form-group label="Documento de calificación:" class="mb-2">
                                                            <b-input-group>
                                                                <b-input-group-prepend v-if="datosProveedor.urlDocumentoCalificacion != ''">
                                                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosProveedor.urlDocumentoCalificacion)" v-c-tooltip="'Descargar'">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file ref="fileActualizar1" v-model="datosProveedor.documentoCalificacion" :state="(getValidationState(validationContext) )" :placeholder="datosProveedor.nombreDocumentoCalificacion || 'Elija un archivo o arrástrelo aquí..'" drop-placeholder="Arrastre el documento aquí.."  :accept="accept.toString()" browse-text="Subir" v-on:change="handleFileUploadActualizar1"></b-form-file>
                                                            </b-input-group>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="4" v-else>
                                                    <validation-provider name="documento de calificación" v-slot="validationContext">
                                                        <b-form-group label="Documento de calificación:" class="mb-2">
                                                            <b-form-file ref="file1" v-model="datosProveedor.documentoCalificacion" v-on:change="handleFileUpload1" :state="getValidationState(validationContext)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir"></b-form-file>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="4" v-if="$route.params.id">
                                                    <validation-provider name="evaluación de proveedores" v-slot="validationContext">
                                                        <b-form-group label="Evaluación de proveedores:" class="mb-2">
                                                            <b-input-group>
                                                                <b-input-group-prepend v-if="datosProveedor.urlEvaluacionProveedor != ''">
                                                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosProveedor.urlEvaluacionProveedor)" v-c-tooltip="'Descargar'">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file ref="fileActualizar1" v-model="datosProveedor.evaluacionProveedor" :state="(getValidationState(validationContext) )" :placeholder="datosProveedor.nombreEvaluacionProveedor || 'Elija un archivo o arrástrelo aquí...'" drop-placeholder="Arrastre el documento aquí.." :accept="accept.toString()" browse-text="Subir" v-on:change="handleFileUploadActualizar2"></b-form-file>
                                                            </b-input-group>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="4" v-else>
                                                    <validation-provider name="evaluación de proveedores" v-slot="validationContext">
                                                        <b-form-group label="Evaluación de proveedores:" class="mb-2">
                                                            <b-form-file ref="file2" v-model="datosProveedor.evaluacionProveedor" v-on:change="handleFileUpload2" :state="getValidationState(validationContext)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir"></b-form-file>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="materiales, suministro, servicio que ofrece:" :rules="{ }" v-slot="validationContext">
                                                        <b-form-group label="Materiales, suministro, servicio que ofrece:" class="mb-2">
                                                            <b-form-textarea v-model="datosProveedor.materialesSuministro" placeholder="Ingrese los materiales" rows="3" max-rows="6" :state="getValidationState(validationContext)"></b-form-textarea>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="6">
                                                    <validation-provider name="observaciones:" :rules="{ }" v-slot="validationContext">
                                                        <b-form-group label="Observaciones" class="mb-2">
                                                            <b-form-textarea v-model="datosProveedor.observaciones" placeholder="Ingrese las observaciones" rows="3" max-rows="6" :state="getValidationState(validationContext)"></b-form-textarea>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                        <b-tab title="Convenios">
                                            <b-row>
                                                <b-col lg="12">
                                                    <CButton color="dark" class="float-right" @click="registrarConvenio()">
                                                        <i class="fas fa-plus fa-sm"></i> Agregar
                                                    </CButton>
                                                </b-col>
                                                <b-col md="12 my-2">
                                                    <b-table :items="datosProveedor.listaConvenios.filter(x =>x.estado == 2)" :fields="camposConvenios" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                        <template v-slot:cell(nombre)="row">
                                                            <validation-provider :name="'nombre1'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input placeholder="Ingrese convenios" :state="getValidationState(validationContext)" type="text" v-model="row.item.nombre"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(opciones)="param">
                                                            <b-button variant="danger" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarConvenio(param)">
                                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>

                                            </b-row>
                                        </b-tab>
                                        <b-tab title="Evidencias">
                                            <b-row>
                                                <b-col lg="12">
                                                    <CButton color="dark" class="float-right" @click="registrarEvidencia()">
                                                        <i class="fas fa-plus fa-sm"></i> Agregar
                                                    </CButton>
                                                </b-col>
                                                <b-col md="12 my-2">
                                                    <b-table :items="datosProveedor.listaEvidencias.filter(x => x.estado == 2)" :fields="camposEvidencias" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                        <template v-slot:cell(nombre)="row">
                                                            <validation-provider :name="'nombre2'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input placeholder="Ingrese evidencias" :state="getValidationState(validationContext)" type="text" v-model="row.item.nombre"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(opciones)="param">
                                                            <b-button v-if="param" variant="danger" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarEvidencia(param)">
                                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>

                                            </b-row>
                                        </b-tab>
                                    </b-tabs>
                                    <b-col lg="12" class="text-center my-2">
                                        <b-button :disabled="disabled" variant="dark" class=" mr-2" :to="{name: 'Proveedores'}">
                                            <i class="fas fa-arrow-left"> </i> Volver
                                        </b-button>
                                        <b-button v-if="$route.params.id  && checkPermissions('009-REC-PRO','u')==1" :disabled="disabled" variant="success" type="submit">
                                            <i class="fas fa-save"></i> Guardar
                                        </b-button>
                                        <b-button v-if="!$route.params.id && checkPermissions('009-REC-PRO','c')==1" :disabled="disabled" variant="success" type="submit">
                                            <i class="fas fa-save"></i> Guardar
                                        </b-button>
                                    </b-col>
                                </b-form>
                            </validation-observer>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
export default {
    data() {
        return {
            datosSession: {
                idCliente: '',
            },
            disabled: false,

            datosProveedor: {
                idProveedor: '',
                fechaRegistro: moment().format('YYYY-MM-DD'),
                codigo: '',
                razonSocial: '',
                ruc: '',
                localidad: '',
                domicilio: '',
                telefono: '',
                email: '',
                contacto: '',
                paginaWeb: '',
                tipo: null,
                probabilidadCredito: 1,
                banco: '',
                nCuenta: '',
                nCuentaDetraccion: '',
                tipologiaProducto: '',
                criterioEvaluacion: null,
                fechaEvaluacion: moment().format('YYYY-MM-DD'),
                aprobado: 1,
                documentoCalificacion: null,
                urlDocumentoCalificacion: '',
                nombreDocumentoCalificacion: '',
                evaluacionProveedor: null,
                urlEvaluacionProveedor: '',
                nombreEvaluacionProveedor: '',
                materialesSuministro: '',
                observaciones: '',
                listaConvenios: [],
                listaEvidencias: [],
            },

            comboCriterio: [{
                idCriterio: 1,
                descripcion: "Excelente"
            }, {
                idCriterio: 2,
                descripcion: "Satisfactorio"
            }, {
                idCriterio: 3,
                descripcion: "Intermedio"
            }, {
                idCriterio: 4,
                descripcion: "Básico"
            }, {
                idCriterio: 5,
                descripcion: "No Apto"
            }, ],

            comboTipoProveedor: [{
                idTipoProveedor: 1,
                descripcion: "Servicio"
            }, {
                idTipoProveedor: 2,
                descripcion: "Producto"
            }, {
                idTipoProveedor: 3,
                descripcion: "Subcontratado"
            }],

            radioPosibilidadCredito: [{
                    value: "1",
                    text: "Si"
                },
                {
                    value: "2",
                    text: "No"
                },
            ],

            radioAprobado: [{
                    value: "1",
                    text: "Si"
                },
                {
                    value: "2",
                    text: "No"
                },
            ],

            camposConvenios: [{
                key: "index",
                label: "N°",
                class: "text-center",
            }, {
                key: 'nombre',
                label: 'Convenios',
                class: 'text-center'
            }, {
                key: 'opciones',
                label: '',
                class: 'text-center'
            }],

            camposEvidencias: [{
                key: "index",
                label: "N°",
                class: "text-center",
            }, {
                key: 'nombre',
                label: 'Evidencias',
                class: 'text-center'
            }, {
                key: 'opciones',
                label: '',
                class: 'text-center'
            }],
            accept: [
                '.vsd', '.vsdx', '.xlsx', '.xls', '.csv',
                '.pdf', '.doc', '.docx', '.ppt', '.pptx',
                '.png', '.jpg', '.jpeg', '.pdf', '.docx',
                'application/msword',
                'application/vnd.ms-excel'
            ]
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        descargarDocumento(url) {
            window.open(url)
        },
        handleFileUpload1(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(vsd|vsdx|xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg|pdf|docx)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file1"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file1"].reset();
                    return;
                }

                this.datosProveedor.documentoCalificacion = this.$refs.file1.files[0];
            }
        },
        handleFileUpload2(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(vsd|vsdx|xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg|pdf|docx)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file2"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file2"].reset();
                    return;
                }
                this.datosProveedor.evaluacionProveedor = this.$refs.file2.files[0];
            }
        },
        handleFileUploadActualizar1(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(vsd|vsdx|xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg|pdf|docx)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["fileActualizar1"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["fileActualizar1"].reset();
                    return;
                }
                this.datosProveedor.documentoCalificacion = this.$refs.fileActualizar1.files[0];
            }
        },
        handleFileUploadActualizar2(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(vsd|vsdx|xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg|pdf|docx)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["fileActualizar2"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["fileActualizar2"].reset();
                    return;
                }

                this.datosProveedor.evaluacionProveedor = this.$refs.fileActualizar2.files[0];
            }
        },
        guardarCambios() {
            if (this.$route.params.id) {
                this.actualizarProveedor();
            } else if (!this.$route.params.id) {
                this.registrarProveedor();
            }
        },
        registrarProveedor() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            formData.append("file1", me.datosProveedor.documentoCalificacion);
            formData.append("file2", me.datosProveedor.evaluacionProveedor);

            formData.append("folder", 'proveedores');
            /*---Datos del objeto--*/
            formData.append("idCliente", me.datosSession.idCliente);
            formData.append("fechaRegistro", me.datosProveedor.fechaRegistro);
            formData.append("codigo", me.datosProveedor.codigo);
            formData.append("razonSocial", me.datosProveedor.razonSocial);
            formData.append("ruc", me.datosProveedor.ruc);
            formData.append("localidad", me.datosProveedor.localidad);
            formData.append("domicilio", me.datosProveedor.domicilio);
            formData.append("telefono", me.datosProveedor.telefono);
            formData.append("email", me.datosProveedor.email);
            formData.append("contacto", me.datosProveedor.contacto);
            formData.append("paginaWeb", me.datosProveedor.paginaWeb);
            formData.append("tipo", me.datosProveedor.tipo);
            formData.append("probabilidadCredito", me.datosProveedor.probabilidadCredito);
            formData.append("banco", me.datosProveedor.banco);
            formData.append("nCuenta", me.datosProveedor.nCuenta);
            formData.append("nCuentaDetraccion", me.datosProveedor.nCuentaDetraccion);
            formData.append("tipologiaProducto", me.datosProveedor.tipologiaProducto);
            formData.append("criterioEvaluacion", me.datosProveedor.criterioEvaluacion);
            formData.append("fechaEvaluacion", me.datosProveedor.fechaEvaluacion);
            formData.append("aprobado", me.datosProveedor.aprobado);
            formData.append("materialesSuministro", me.datosProveedor.materialesSuministro);
            formData.append("observaciones", me.datosProveedor.observaciones);
            formData.append("listaConvenios", JSON.stringify(me.datosProveedor.listaConvenios));
            formData.append("listaEvidencias", JSON.stringify(me.datosProveedor.listaEvidencias));

            formData.append("nombreDocumentoCalificacion", '');
            formData.append("urlDocumentoCalificacion", '');
            formData.append("nombreEvaluacionProveedor", '');
            formData.append("urlEvaluacionProveedor", '');

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-proveedor",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.disabled = false;
                    me.$router.push({
                        name: 'Proveedores'
                    });
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },

        actualizarProveedor() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            formData.append("file1", me.datosProveedor.documentoCalificacion);
            formData.append("file2", me.datosProveedor.evaluacionProveedor);

            formData.append("folder", 'proveedores');
            /*---Datos del objeto--*/
            formData.append("idCliente", me.datosSession.idCliente);
            formData.append("fechaRegistro", me.datosProveedor.fechaRegistro);
            formData.append("codigo", me.datosProveedor.codigo);
            formData.append("razonSocial", me.datosProveedor.razonSocial);
            formData.append("ruc", me.datosProveedor.ruc);
            formData.append("localidad", me.datosProveedor.localidad);
            formData.append("domicilio", me.datosProveedor.domicilio);
            formData.append("telefono", me.datosProveedor.telefono);
            formData.append("email", me.datosProveedor.email);
            formData.append("contacto", me.datosProveedor.contacto);
            formData.append("paginaWeb", me.datosProveedor.paginaWeb);
            formData.append("tipo", me.datosProveedor.tipo);
            formData.append("probabilidadCredito", me.datosProveedor.probabilidadCredito);
            formData.append("banco", me.datosProveedor.banco);
            formData.append("nCuenta", me.datosProveedor.nCuenta);
            formData.append("nCuentaDetraccion", me.datosProveedor.nCuentaDetraccion);
            formData.append("tipologiaProducto", me.datosProveedor.tipologiaProducto);
            formData.append("criterioEvaluacion", me.datosProveedor.criterioEvaluacion);
            formData.append("fechaEvaluacion", me.datosProveedor.fechaEvaluacion);
            formData.append("aprobado", me.datosProveedor.aprobado);
            formData.append("materialesSuministro", me.datosProveedor.materialesSuministro);
            formData.append("observaciones", me.datosProveedor.observaciones);
            formData.append("listaConvenios", JSON.stringify(me.datosProveedor.listaConvenios));
            formData.append("listaEvidencias", JSON.stringify(me.datosProveedor.listaEvidencias));

            formData.append("idProveedor", me.datosProveedor.idProveedor);
            formData.append("nombreDocumentoCalificacion", me.datosProveedor.nombreDocumentoCalificacion);
            formData.append("urlDocumentoCalificacion", me.datosProveedor.urlDocumentoCalificacion);
            formData.append("nombreEvaluacionProveedor", me.datosProveedor.nombreEvaluacionProveedor);
            formData.append("urlEvaluacionProveedor", me.datosProveedor.urlEvaluacionProveedor);

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-proveedor",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.disabled = false;
                    me.$router.push({
                        name: 'Proveedores'
                    });
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        limpiar() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
                this.datosProveedor.criterioEvaluacion = null;
            });
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        blurCriterio() {
            this.computedForm.refs.criterio.validate();
        },
        blurTipoProveedor() {
            this.computedForm.refs.tipo.validate();
        },

        registrarConvenio() {
            let me = this;
            me.datosProveedor.listaConvenios.push({
                nombre: '',
                estado: 2
            })
        },
        registrarEvidencia() {
            let me = this;
            me.datosProveedor.listaEvidencias.push({
                nombre: '',
                estado: 2
            })
        },

        obtenerProveedor() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-proveedor", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            idProveedor: me.$route.params.id
                        },
                    }
                )
                .then(function (response) {
                    me.datosProveedor.idProveedor = response.data[0].idProveedor;
                    me.datosProveedor.fechaRegistro = response.data[0].fechaRegistro;
                    me.datosProveedor.codigo = response.data[0].codigo;
                    me.datosProveedor.razonSocial = response.data[0].razonSocial;
                    me.datosProveedor.ruc = response.data[0].ruc;
                    me.datosProveedor.localidad = response.data[0].localidad;
                    me.datosProveedor.domicilio = response.data[0].domicilio;
                    me.datosProveedor.telefono = response.data[0].telefono;
                    me.datosProveedor.email = response.data[0].email;
                    me.datosProveedor.contacto = response.data[0].contacto;
                    me.datosProveedor.paginaWeb = response.data[0].paginaWeb;
                    me.datosProveedor.tipo = response.data[0].tipo;
                    me.datosProveedor.probabilidadCredito = response.data[0].probabilidadCredito;
                    me.datosProveedor.banco = response.data[0].banco;
                    me.datosProveedor.nCuenta = response.data[0].nCuenta;
                    me.datosProveedor.nCuentaDetraccion = response.data[0].nCuentaDetraccion;
                    me.datosProveedor.tipologiaProducto = response.data[0].tipologiaProducto;
                    me.datosProveedor.criterioEvaluacion = response.data[0].criterioEvaluacion;
                    me.datosProveedor.fechaEvaluacion = response.data[0].fechaEvaluacion;
                    me.datosProveedor.aprobado = response.data[0].aprobado;
                    me.datosProveedor.urlDocumentoCalificacion = response.data[0].urlDocumentoCalificacion;
                    me.datosProveedor.nombreDocumentoCalificacion = response.data[0].nombreDocumentoCalificacion;
                    me.datosProveedor.urlEvaluacionProveedor = response.data[0].urlEvaluacionProveedor;
                    me.datosProveedor.nombreEvaluacionProveedor = response.data[0].nombreEvaluacionProveedor;
                    me.datosProveedor.materialesSuministro = response.data[0].materialesSuministro;
                    me.datosProveedor.observaciones = response.data[0].observaciones;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!!');
                    me.$router.push({
                        name: 'Proveedores'
                    });
                });
        },

        obtenerProveedorConvenios() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-proveedor-convenios", {
                        params: {
                            idProveedor: me.$route.params.id
                        },
                    }
                )
                .then(function (response) {
                    me.datosProveedor.listaConvenios = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!!');
                });
        },
        obtenerProveedorEvidencias() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-proveedor-evidencias", {
                        params: {
                            idProveedor: me.$route.params.id
                        },
                    }
                )
                .then(function (response) {
                    me.datosProveedor.listaEvidencias = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!!');
                });
        },
        eliminarConvenio(param) {
            let me = this;

            if (this.$route.params.id) {
                let listaConvenios = me.datosProveedor.listaConvenios.filter(x => x.estado == 2);
                if (listaConvenios[param.index].idProveedor) {
                    listaConvenios[param.index].estado = 1
                } else if (!listaConvenios[param.index].idProveedor) {
                    listaConvenios[param.index].estado = 0
                }
            } else {
                me.datosProveedor.listaConvenios.splice(param.index, 1)
            }
        },

        eliminarEvidencia(param) {
            let me = this;
            if (this.$route.params.id) {
                let listaEvidencias = me.datosProveedor.listaEvidencias.filter(x => x.estado == 2);
                if (listaEvidencias[param.index].idProveedor) {
                    listaEvidencias[param.index].estado = 1
                } else if (!listaEvidencias[param.index].idProveedor) {
                    listaEvidencias[param.index].estado = 0
                }
            } else {
                me.datosProveedor.listaEvidencias.splice(param.index, 1)
            }
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        }
    },
    watch: {

    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            if (this.$route.params.id) {
                this.obtenerProveedor();
                this.obtenerProveedorConvenios();
                this.obtenerProveedorEvidencias();
            }
        }
    }

}
</script>
